<template>
    <div class="shoppingTip">
        <div class="title">
            加入购物车提示
            <i class="el-icon-close" @click="continu"></i>
        </div>
        <div class="line2">
            <img src="../assets/imgs/effect16.png">
            恭喜你，加入购物车成功！
        </div>
        <div class="line3">
            购物车中共<span>{{num}}</span>件商品
        </div>
        <div class="line4">
            <div class="continu btn" @click="continu">继续购物</div>
            <div class="check btn" @click="check">查看购物车</div>
        </div>
    </div>
</template>

<script>
export default {
    props:['num'],
    methods: {
        continu(){
            this.$emit("continu")
        },
        check(){
            this.$router.push({
                path:"/myShoppingCart"
            })
        },
    },
}
</script>

<style lang="less" scoped>
.shoppingTip{
    position: fixed;
    z-index: 1000;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 1100px;
    height: 475px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 3px 6px  rgba(0, 0, 0, 0.15);
    .title{
        height: 80px;
        padding: 0 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 26px;
        font-weight: 400;
        color: rgba(0, 0, 0, 1);
        background: rgba(247, 247, 247, 1);
        i{
            cursor: pointer;
        }
    }
    .line2{
        margin-top: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 31px;
        font-weight: 400;
        color: rgba(56, 56, 56, 1);
        &>img{
            margin-right: 10px;
            width: 61px;
            height: 61px;
        }
    }
    .line3{
        margin-top: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 31px;
        font-weight: 400;
        color: rgba(56, 56, 56, 1);
        &>span{
            color: rgba(212, 48, 48, 1);
            font-weight: 700;
        }
    }
    .line4{
        margin-top: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        .btn{
            height: 76px;
            width: 203px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(238, 238, 238, 1);
            border: 2px solid rgba(221, 221, 221, 1);
            font-size: 26px;
            font-weight: 400;
            color: rgba(56, 56, 56, 1);
            cursor: pointer;
            margin: 0 10px;
            border-radius: 4px;
            &.check{
                background: rgba(212, 58, 42, 1);
                color: rgba(255, 255, 255, 1);
                border-color: rgba(212, 58, 42, 1);
            }
        }
    }
}
</style>